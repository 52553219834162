import React, { Fragment } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router" // Add this import

import SEO from "./SEO"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Container from "layout/Container"
import Notification from "./Notification"
import RadialStepper from "../Elements/RadialStepper"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import PrivateRoute from "./PrivateRoute"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {number} currentStep current step on the progress tracker
 * @param {number} totalSteps total steps of the progress tracker
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  currentStep,
  totalSteps,
  display = { footer: true, helpCenterBanner: true, radialStepper: true },
  path,
  pageContext,
}) => {
  const location = useLocation() // Add this hook to get location

  let pathArray = path ? path?.split("/") : []

  return (
    <PrivateRoute location={location}>
      <Fragment>
        <SEO title={seoTitle} />
        <Navbar path={pathArray} />
        <Notification />
        {title && (
          <Container isCentered>
            <div className="is-flex is-justify-content-space-between is-align-items-center is-hidden-desktop is-hidden-tablet">
              <div className="mr-1">
                <h2
                  className={classNames(
                    "has-text-left mb-0 has-text-primary is-size-4-mobile",
                    {
                      "pb-0": !subtitle,
                      "pb-2": !!subtitle,
                    }
                  )}
                >
                  {title}
                  <h4 className="subtitle has-text-grey has-text-left my-1 is-size-4 is-size-6-mobile">
                    {subtitle}
                  </h4>
                </h2>
              </div>
              {!!currentStep &&
              !!totalSteps &&
              display?.radialStepper === true ? (
                <RadialStepper
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              ) : null}
            </div>
            <div className="columns is-align-items-center is-hidden-mobile">
              <div className="column">
                <h2
                  className={classNames(
                    "has-text-centered mb-0 has-text-primary",
                    {
                      "pb-0": !subtitle,
                      "pb-2": !!subtitle,
                    }
                  )}
                >
                  {title}
                  <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-4">
                    {subtitle}
                  </h4>
                </h2>
              </div>
            </div>
          </Container>
        )}
        <main className="mb-3">{children}</main>
        {display.helpCenterBanner && <HelpCenterBanner />}
        {display.footer && <Footer />}
      </Fragment>
    </PrivateRoute>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  path: PropTypes.array,
}

export default Layout

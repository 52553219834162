import React, { Fragment, useEffect, useState } from "react"
import { navigate } from "gatsby"
import Cookies from "js-cookie"

import { getHasValidatedLastName } from "../Auth/services/getHasValidatedLastNameValue"
import { navigateToVerifyLastNamePage } from "../Auth/services/navigateToVerifyLastNamePage"

const PrivateRoute = ({ isPrivate, children, location }) => {
  const [userAuthenticated, setUserAuthenticated] = useState(null) // Start with `null` to avoid premature redirects

  useEffect(() => {
    if (typeof window === "undefined") return

    const loggedInUser = Cookies.get("loggedInUser")
    setUserAuthenticated(!!loggedInUser) // Converts to `true` if the cookie exists
  }, [])

  useEffect(() => {
    if (userAuthenticated === null) return // Don't run logic until auth state is determined

    const urlSearchParams = new URLSearchParams(location.search)
    const zendeskId = urlSearchParams.get("z") || ""
    const hasValidatedLastName = getHasValidatedLastName()
    const currentPath = location.pathname

    if (
      currentPath === "/careers" ||
      currentPath.includes("proof-of-payment")
    ) {
      return // Allow access without authentication
    }

    if (!userAuthenticated && currentPath !== "/") {
      navigate("/")
      return
    }

    if (isPrivate && !hasValidatedLastName) {
      navigateToVerifyLastNamePage({ zendeskId })
    }
  }, [userAuthenticated, isPrivate, location])

  if (userAuthenticated === null) return null

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
